import type { Schemas } from '#shopware'

export const useProductStream = (streamId: string) => {
  const { apiClient } = useShopwareContext()

  const search = async (
    criteria: Schemas['Criteria']
  ): Promise<
    Schemas['EntitySearchResult'] & { elements: Schemas['Product'][] }
  > => {
    criteria.filter = [
      ...(criteria.filter || []),
      {
        type: 'equalsAny',
        field: 'streamIds',
        value: [streamId],
      },
    ]
    return await apiClient.invoke(`readProduct post /product`, {
      body: criteria,
    })
  }

  return {
    search,
  }
}
